






























































































































































































































































































































































































































































































































































































































































































































































































































































































































.bm-multiply {
  mix-blend-mode: multiply;
}

.ol-control {
  border-radius: 0;
  padding: 0;
  background-color: rgb(220, 220, 220);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ol-control:hover {
  background-color: rgb(220, 220, 220);
}

.ol-zoom {
  top: 0.5em;
  right: 0.5em;
  left: inherit;
  font-size: 1.13rem;
}

.ol-zoom .ol-zoom-in {
  border-radius: 0;
  margin-bottom: 1px;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0;
}

.ol-control button {
  background-color: white;
  min-height: 0;
  color: rgb(120, 120, 120);
  border-radius: 0;
  margin: 0;
  height: 2em;
  width: 2em;
}

.ol-control button:focus {
  background-color: white;
  color: rgb(20, 20, 20);
  min-height: 0;
}
.ol-control button:hover {
  background-color: white;
  min-height: 0;
  color: rgb(20, 20, 20);
}

#popup-content {
  width: 100%;
}

.ol-attribution {
  right: unset;
  left: 0.5em;
  background: rgba(255,255,255,0.2) !important;
  box-shadow: unset;

  button {
    display: none;
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  /* padding: 15px; */
  /* border-radius: 10px; */
  /* border: 1px solid #cccccc; */
  bottom: 12px;
  left: -50px;
  /* min-width: 80px; */
  width: max-content;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 0px;
  right: 5px;
}
.ol-popup-closer:after {
  color: white;
  content: "✖";
}

.popup-pager {
  background-color: white;
  position: absolute;
  right: 0px;
  user-select: none;
}


.popup-content,
.popup-title,
.popup-attributes {
  max-width: 22rem;
  box-sizing: content-box;
}

.popup-attributes {
  table {
    thead {
      @apply font-bold;
    }
    tbody {
      padding-top: 0.25rem;

      td {
        padding-top: 0.15rem;
      }
    }
    .attribute {
      max-width: 8rem;
      padding-right: 1rem;
    }
  }
}

.material-link {
  @apply align-bottom; 
  @apply text-lg;
  margin-top: -3px;
  transform: rotate(-45deg);
}

















































































.slider-label {
  text-align: center;
  padding-top: 1rem;
}
.vue-slider-piecewise-label {
  white-space: inherit !important;
}
















































































































.sidebar-home {
  @apply p-10 pt-6;

  h1 {
    @apply text-green;
    // width: 330px;
  }

  .hero {
    position: relative;
    padding-bottom: 1rem;

    img {
      min-width: 141px;
      max-height: 200px;
    }
  }

  .mobile-more-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 2rem;

    .home__slide {
      width: calc(50% - 0.5rem);
      aspect-ratio: 1 / 1;
      margin-top: 0.5em;
    }
  }

  p+p {
    margin-top: 1rem;
  }

  .gotodata {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;

  }
}

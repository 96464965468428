





































































:root {}

iframe {
  @apply bg-grey-darkest;
}

.tile-grid {
  $logoHeight: 100px;
  $footerHeight: 30px;
  $manualPadding: 0.5em;
  gap: $manualPadding;
  $contentSize: calc(50vh - #{$footerHeight / 2} - #{$manualPadding} - #{$logoHeight/2});
  $containerHeight: calc(100vh - #{$footerHeight});

  display: grid;
  grid-template-rows: 1fr 1fr #{$logoHeight};
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: end;
  height: $containerHeight;
  margin: auto;
  width: calc(100% - #{2 * $manualPadding});

  .grid__slide {
    height: calc(#{$contentSize} - #{4 * $manualPadding});
    width: $contentSize;
  }

  .logo {
    max-width: 300px;
    max-height: #{$logoHeight};
    width: auto;
    height: auto;
    object-fit: scale-down;
  }

  .cover-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: 1 / 1 / end / span 3;
    height: 100%;
  }

  .cover-close {
    padding: 0.5em 1.5em;
    font-weight: bold;
    border-radius: 10px;
    margin-left: auto;
  }

  .cover-logos {
    height: #{$logoHeight};
    grid-area: 3 / 1 / end / span 3;
  }

  .cover-video {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: stretch;
    align-items: center;
    align-content: center;
  }
}

.teaser {
  @apply text-green font-script text-3xl pt-3 pb-3;
}

.embed-responsive-item {
  width: 100%;
}


























































































.slider-label {
  text-align: center;
  padding-top: 1rem;
  color: white;
}

.time-control-wrapper {
  width: 18rem;
}









































.item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-basis: calc(33.3333% - 0.5rem);
  // height: 5rem;

  // margin: 0.25rem;
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  // padding: 0.25rem;
  text-align: center;

  cursor: pointer;
}

.bg-red .item.active {
  @apply bg-red-dark;
}
.bg-orange .item.active {
  @apply bg-orange-dark;
}
.bg-green-alt .item.active {
  @apply bg-green-alt-dark;
}
.bg-teal .item.active {
  @apply bg-teal-dark;
}
.bg-blue .item.active {
  @apply bg-blue-dark;
}

.item-text {
  font-size: 0.75rem;
}

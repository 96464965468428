













































































































.legend {
  // width: max-content;
  max-height: 500px;
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;

  ul {
    list-style: none;
    padding-left: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

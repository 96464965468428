



























































































































































































































































































































































































































































































































































































@responsive {
  .flex-fixed {
    flex: 0 0 500px;
  }
}

.mobile-map-container {
  transition: transform ease-in-out 0.13333s;

  &>* {
    -webkit-transform: translateZ(0);
  }

  &.hide {
    transform: translateY(-100vh);
  }
}

.mobile-map-toggle-container {
  position: absolute;
  height: 35px;
  bottom: 0;
  left: 50%;
  margin-left: -4.125rem;
  width: 8.25rem;
}

.mobile-map {
  .map-frame {
    width: 100vw;
    height: 100vh;
  }
}

.tr-controls {
  width: 2.25rem;
  height: 2.25rem;
  right: .55rem;
  top: 5.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);

  .material-icons {
    font-size: 1.5rem;
  }
}

.climate-layer-section-picker {
  @apply flex text-xs justify-between items-center text-center;

  &>div {
    flex-grow: 1;
    cursor: pointer;
    flex-basis: calc(20% - 0.25rem);
    width: calc(20% - 0.25rem);
    margin: 0.25 rem;
    height: 6rem;

    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 0.05 rem;

    img {
      display: block;
      width: 45px;
    }
  }

  .hc {
    @apply bg-red;
  }

  .gs {
    @apply bg-green-alt;
  }

  .ep {
    @apply bg-blue;
  }

  .ap {
    @apply bg-teal;
  }

  .at {
    @apply bg-orange;
  }
}

.climate-layer-section {
  @apply flex flex-wrap justify-between;
}

.climate-layer-section::after {
  content: "";
  flex-basis: calc(33.333% - 0.5rem);
}

.map-frame {
  height: calc(100vh - 30px);
  min-height: 0;
  min-width: 0;
}

.app-container {
  min-height: calc(100vh - 30px);
}

#footer {
  flex-basis: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.time-container {
  max-height: 210px;
  overflow-y: auto !important;
  pointer-events: auto;
}


.my-style {
  // Style of the notification itself - group notify

  padding: 10px;
  margin: 10px;
  font-size: 14px;
  background: #44A4FC !important;
  color: white;
  border-left: 5px solid #187FE7;


  .notification-title {
    // Style for title line
  }

  .notification-content {
    // Style for content
  }
}




















































.scale {
  min-width: 260px;

  span { width: 1%; }

  &.reversed {
    transform: scaleX(-1);
  }
}



























.logo {
  width: 350px;
  height: 105px;
}

#nav-bar {
  a {
    color: inherit;
    text-decoration: none;
  }

  .title {
    width: 18rem;
  }
}

#nav {
  padding: 1rem 2rem 0.5rem;

  a {
    @apply text-white no-underline text-lg;
    width: max-content;

    &.router-link-active {
      @apply text-white no-underline border-solid border-white border-b-2;
    }
  }
}

svg {
  vertical-align: middle;

  &.size-medium {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 768) {
  #nav {
    a:last-of-type {
      margin-right: 0px;
    }
  }
}

.nav-menu {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  width: 100%;
  scale: 0.8;
  font-family: Lato, Calibri, Arial, sans-serif;

}












































































iframe {
  @apply bg-grey-darkest;
}

.teaser {
  @apply text-green font-script text-3xl pt-3 pb-3;
}

.gateway {
  height: inherit;
}

.gateway button.go,
.gateway a.go {
  display: inline-block;
  @apply ml-4 font-sans bg-green text-white font-bold py-2 px-4;
}

.gateway button.go:hover {
  @apply bg-green-dark;
}

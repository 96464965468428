





























.legend_simple-item {
  display: flex;
}

.legend_complex-patches {
  list-style: none;
  @apply pl-2 pt-0 pb-0 text-xs;

  li {
    @apply flex;
    height: 15px;

    span {
      @apply flex-grow;
    }
  }
}

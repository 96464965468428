



















































#map-layer-icons {
  top: 8.5rem;
  pointer-events: none;
  z-index: 15;

  &.mobile {
    top: 0.5rem;
  }

  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }

  div {
    margin-top: 0rem;
  }

  .item-patch {
    pointer-events: auto;
    cursor: pointer;
  }

  .item-name {
    // display: none;
    min-width: 8rem;
    visibility: hidden;
    color: white;
    position: relative;

    .description {
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }

  .item-patch:hover + .item-name,
  .item-name:hover {
    // display: flex;
    pointer-events: auto;
    visibility: visible;
  }
}

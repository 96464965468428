
































#footer {
  // line-height: 30px;
  @apply text-green uppercase;
  overflow: hidden;
}

.footer-links {
  a {
    @apply text-green uppercase no-underline;
  }
}

.button-social {
  padding: 0 5px !important;
  line-height: 23px;
  filter: grayscale(100);
  opacity: 0.5;
  @apply text-sm;

  &:hover {
    opacity: 100;
    filter: none;
  }
}

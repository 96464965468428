@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');
@import url('https://fonts.googleapis.com/css?family=Caveat:400');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.standard-sidebar {
  @apply p-10;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.fixed {
  position: fixed;
}

h1,
h2,
h3,
h4 {
  @apply text-green-dark;
}

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply text-lg font-bold;
}

img {
  @apply m-auto;
}

article {
  a {
    @apply text-green-dark no-underline;
  }

  a:hover {
    @apply underline;
  }

  a:visited {
    @apply text-green-medium;
  }

  a[name] {
    padding-top: 30px;
  }

  p.teaser {
    @apply text-green leading-tight font-script text-3xl pt-3 pb-3;
  }
}

article {
  p + p {
    padding-top: 1rem;
  }

  h1 + p {
    padding-top: 1rem;
  }
  
  h2 + p {
    padding-top: 0.8rem;
  }
  
  p:last-child {
    padding-bottom: 1rem;
  }
  
  .learn-more {
    @apply bg-green border-green border-solid border mt-3 mb-3;
  }

  h2, .learn-more + h3 { 
    @apply pt-5; 
  }

  img {
    width: 100%;
    margin: 0.8rem 0 0.8rem 0;
    max-height: 300px;
    object-fit: cover;
  }

  h3 + img {
    padding-top: 0.2rem;
  }

  ul {
    @apply list-disc list-inside pl-4;
  }

  ol {
    @apply list-decimal list-inside pl-4;
  }

  p + ol, p + ul {
    padding-top: 0.75rem;
  }

  ol + p, ul + p {
    padding-top: 0.75rem;
  }
}

.icon-medium {
  width: 42px;
  height: 42px;
}

.nav-sticky-wraper {
  @apply sticky;
  top: -0.01rem;
  z-index: 10;
}

.section-navigation {
  @apply  text-center flex items-center uppercase pt-1 pb-2 bg-grey;

  a {
    @apply text-grey-darkest text-lg no-underline font-condensed;
  }

  div { @apply flex-grow; }
}

.spaced-list {
  padding: 0.3rem;
}

.layer-list {
  @apply  border-green border-solid border-2 mt-3 mb-3 pt-1 pb-1 list-none;
  position: relative;
}

/* hover style just for information */
label:hover:before {
  border: 2px solid config('colors.green-dark') !important;
}

.intro-dark {
  background-color: rgba(255, 255, 255, .3) !important;
}

.intro-none {
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
  box-shadow: none !important;
}

.tippy-tooltip.bcs-theme {
  @apply bg-green-dark;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.bcs-theme .tippy-arrow {
  border-bottom:7px solid config('colors.green-dark');
  border-right:7px solid transparent;
  border-left:7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.bcs-theme .tippy-arrow {
  border-top:7px solid config('colors.green-dark');
  border-right:7px solid transparent;
  border-left:7px solid transparent;
}


// Tiny-Slider
// Version: 2.9.1

.tns-outer {
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  [hidden] { display: none !important; }
  [aria-controls], [data-action] { cursor: pointer; }
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  &.tns-no-subpixel {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > .tns-item {
      float: left;
    }
  }
  &.tns-carousel {
    &.tns-no-subpixel {
      > .tns-item {
        margin-right: -100%;
      }
    }
  }
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible
  // overflow: hidden;
  > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
  > .tns-slide-active {
    position: relative;
    left: auto !important;
  }
  > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.tns-autowidth { display: inline-block; }
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.tns-complete { opacity: 1; }
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh { overflow: hidden; }
.tns-visually-hidden { position: absolute; left: -10000em; }
.tns-transparent { opacity: 0; visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}


// *** Fix a viewport issue in initialization
.tns-vpfix {
  white-space: nowrap;
  > div, > li {
    display: inline-block;
  }
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }
  &-ct {
    width: (100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > div {
      width: (100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
}

/* purgecss start ignore */

.flex-fixed{
  -webkit-box-flex:0;
      -ms-flex:0 0 500px;
          flex:0 0 500px
}

.mobile-map-container{
  -webkit-transition:-webkit-transform ease-in-out 0.13333s;
  transition:-webkit-transform ease-in-out 0.13333s;
  transition:transform ease-in-out 0.13333s;
  transition:transform ease-in-out 0.13333s, -webkit-transform ease-in-out 0.13333s
}

.mobile-map-container>*{
  -webkit-transform:translateZ(0)
}

.mobile-map-container.hide{
  -webkit-transform:translateY(-100vh);
          transform:translateY(-100vh)
}

.mobile-map-toggle-container{
  position:absolute;
  height:35px;
  bottom:0;
  left:50%;
  margin-left:-4.125rem;
  width:8.25rem
}

.mobile-map .map-frame{
  width:100vw;
  height:100vh
}

.tr-controls{
  width:2.25rem;
  height:2.25rem;
  right:.55rem;
  top:5.5rem;
  -webkit-box-shadow:0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24);
          box-shadow:0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)
}

.tr-controls .material-icons{
  font-size:1.5rem
}

.climate-layer-section-picker{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  text-align:center;
  font-size:.75rem
}

.climate-layer-section-picker>div{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  cursor:pointer;
  -ms-flex-preferred-size:calc(20% - 0.25rem);
      flex-basis:calc(20% - 0.25rem);
  width:calc(20% - 0.25rem);
  margin:0.25 rem;
  height:6rem;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -ms-flex-item-align:stretch;
      align-self:stretch;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -ms-flex-line-pack:center;
      align-content:center;
  padding:0.05 rem
}

.climate-layer-section-picker>div img{
  display:block;
  width:45px
}

.climate-layer-section-picker .hc{
  --tw-bg-opacity:1;
  background-color:rgba(236, 91, 73, var(--tw-bg-opacity))
}

.climate-layer-section-picker .gs{
  --tw-bg-opacity:1;
  background-color:rgba(153, 189, 60, var(--tw-bg-opacity))
}

.climate-layer-section-picker .ep{
  --tw-bg-opacity:1;
  background-color:rgba(15, 106, 170, var(--tw-bg-opacity))
}

.climate-layer-section-picker .ap{
  --tw-bg-opacity:1;
  background-color:rgba(48, 147, 143, var(--tw-bg-opacity))
}

.climate-layer-section-picker .at{
  --tw-bg-opacity:1;
  background-color:rgba(233, 157, 80, var(--tw-bg-opacity))
}

.climate-layer-section{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between
}

.climate-layer-section::after{
  content:"";
  -ms-flex-preferred-size:calc(33.333% - 0.5rem);
      flex-basis:calc(33.333% - 0.5rem)
}

.map-frame{
  height:calc(100vh - 30px);
  min-height:0;
  min-width:0
}

.app-container{
  min-height:calc(100vh - 30px)
}

#footer{
  -ms-flex-preferred-size:30px;
      flex-basis:30px
}

.fade-enter-active,.fade-leave-active{
  -webkit-transition:opacity 0.5s;
  transition:opacity 0.5s
}

.fade-enter,.fade-leave-to{
  opacity:0
}

.time-container{
  max-height:210px;
  overflow-y:auto !important;
  pointer-events:auto
}

.my-style{
  padding:10px;
  margin:10px;
  font-size:14px;
  background:#44A4FC !important;
  color:white;
  border-left:5px solid #187FE7
}

@media (min-width: 640px){
  .sm\:flex-fixed{
    -webkit-box-flex:0;
        -ms-flex:0 0 500px;
            flex:0 0 500px
  }
}

@media (min-width: 768px){
  .md\:flex-fixed{
    -webkit-box-flex:0;
        -ms-flex:0 0 500px;
            flex:0 0 500px
  }
}

@media (min-width: 1024px){
  .lg\:flex-fixed{
    -webkit-box-flex:0;
        -ms-flex:0 0 500px;
            flex:0 0 500px
  }
}

@media (min-width: 1280px){
  .xl\:flex-fixed{
    -webkit-box-flex:0;
        -ms-flex:0 0 500px;
            flex:0 0 500px
  }
}

@media (min-width: 1536px){
  .\32xl\:flex-fixed{
    -webkit-box-flex:0;
        -ms-flex:0 0 500px;
            flex:0 0 500px
  }
}

/* purgecss end ignore */
